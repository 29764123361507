import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { contactContext } from '../context'
// import Modal from 'react-modal'
function ContactModal() {
    // if (open)
    const {toggleModal:open, setToggle} = useContext(contactContext)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phNo, setPhNo] = useState('')
    const [country, setCountry] = useState('')
    const [service, setService] = useState('')
    const [msg, setMsg] = useState('')

    const onClose =()=> setToggle(false)

    useEffect(() => {
        setName("")
        setEmail("")
        setPhNo("")
        setCountry("")
        setService("")
        setMsg("")
    }, [open])

    const onSubmit = () => {
        setName("")
        setEmail("")
        setPhNo("")
        setCountry("")
        setService("")
        setMsg("")
        onClose()
    }
    if (open)
        return (
            // <div
            // className='w-[50vw]'
            // isOpen={open}
            // onRequestClose={onClose}
            // contentLabel="Modal"
            // style={{maxWidth: '50vw'}}
            // className='z-[10]'
            // style={{ zIndex: '999999999999999999999999999999' }}
            // style={{position: 'sticky', top: '20vh', left: '0', width: '100%', zIndex: '99999'}}
            // >
            <div className='fixed right-2 bottom-2 z-[99999] p-10 max-w-[95vw] bg-white md:max-w-[50vw] h-[90%] rounded-lg overflow-scroll'>
                <div className='ml-auto cursor-pointer w-10 pb-2 px-4 '>
                    <button className='btn text-white rounded-full px-[1.12rem]' onClick={onClose}>x</button>
                </div>
                <div className="form-control ml-auto  w-full max-w-sm">
                    <div className='mb-3'>
                        <label className="label">
                            <span className="label-text font-semibold text-black">Name</span>
                        </label>
                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Type here" className="input input-bordered w-full max-w-xs bg-white text-gray-900" />
                    </div>
                    <div className='mb-3'>
                        <label className="label">
                            <span className="label-text font-semibold text-black">Email</span>
                        </label>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Type here" className="input input-bordered w-full max-w-xs bg-white text-gray-900" />
                    </div>
                    <div className='mb-3'>
                        <label className="label">
                            <span className="label-text font-semibold text-black">Phone Number</span>
                        </label>
                        <input type="tel" value={phNo} onChange={(e) => setPhNo(e.target.value)} placeholder="Type here" className="input input-bordered w-full max-w-xs bg-white text-gray-900" />
                    </div>
                    <div className='mb-3'>
                        <label className="label">
                            <span className="label-text font-semibold text-black">Country</span>
                        </label>
                        <input type="text" value={country} onChange={e => setCountry(e.target.value)} placeholder="Type here" className="input input-bordered w-full max-w-xs bg-white text-gray-900" />
                    </div>
                    <div className='mb-3'>
                        <label className="label">
                            <span className="label-text font-semibold text-black">Service</span>
                        </label>
                        <select value={service} onChange={e => setService(e.target.value)} className="select select-bordered w-full max-w-xs bg-white text-gray-900">
                            <option disabled selected>Select Service</option>
                            <option value="Automation">Automation</option>
                            <option value='Web Development'>Web Development</option>
                        </select>
                        <input type="text" placeholder="Type here" className="input input-bordered w-full max-w-xs bg-white text-gray-900" />
                    </div>
                    <div className='mb-3'>
                        <label className="label">
                            <span className="label-text font-semibold text-black">Message</span>
                        </label>
                        <textarea value={msg} onChange={e => setMsg(e.target.value)} className="textarea w-full textarea-bordered h-24 bg-white text-gray-900" placeholder="Bio"></textarea>
                    </div>

                    <div className='mb-3 mx-auto'>
                        <button onClick={onSubmit} className='btn mx-auto w-[125px]'>
                            Submit
                        </button>
                    </div>
                </div>
            </div>


            // </div>
        )
}
export default ContactModal
