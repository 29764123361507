
function Feature({title='Experience', count='2+ yrs', img}) {
  return (
    <div className="rounded-3xl z-[2] bg-gray-600 text-white h-[150px] md:h-[300px] w-[150px] md:w-[300px] flex flex-col items-center text-center">
      <div className="rounded-full relative bg-gray-100 -top-10 md:-top-20 w-[75px] md:w-[150px] overflow-hidden" >
              <img alt='team' className="bg-contain p-3 md:p-10 mx-auto"  src={img} />
      </div>
      <span className="text-xl md:text-2xl -mt-3 mb-3 md:mb-6">
        {title}
      </span>
      <span className="text:3xl md:text-5xl  font-bold">
        {count}
      </span>
    </div>
  )
}

export default Feature
