import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import Homepage from './pages/index/Homepage';
import Services from './pages/Services';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { MainContext } from './context';

function App() {

  return (
    <div className=" h-full">
      <Router>
        <MainContext>
          <NavBar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/services" element={<Services />} />
          </Routes>
          <Footer />
        </MainContext>
      </Router>
    </div >
  );
}

export default App;
