import { NavLink } from 'react-router-dom'
import ContactModal from './ContactModal'
import { useContext } from 'react'
import { contactContext } from '../context'
function NavBar() {

  const {toggleModal, setToggle}= useContext(contactContext)
  const handleToggleModal=(val)=>setToggle(val)
  return (
    <>
    <div className="navbar sticky top-0  sm:p-4 sm:px-8 z-[100] text-white bg-gray-900">
      {/* <div className="lg:w-[70vw] lg:mx-auto "> */}
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost md:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </label>
          <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
            <li><NavLink  to='/'>Home</NavLink></li>
            {/* <li tabIndex={0}>
          <a className="justify-between">
            Parent
            <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/></svg>
          </a>
          <ul className="p-2">
            <li><a>Submenu 1</a></li>
            <li><a>Submenu 2</a></li>
          </ul>
        </li> */}
            <li><NavLink to='/services'>Services</NavLink></li>
          </ul>
        </div>
        <div className="btn btn-ghost normal-case text-xl">
          <img src="mystic-bg.png" className='w-[80px] sm:w-40 ' alt='logo' />
          </div>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu-horizontal lg:gap-x-6 px-1">
          <li><NavLink className='active:font-semibold' to='/'>Home</NavLink></li>
          {/* <li tabIndex={0}>
        <a>
          Parent
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </a>
        <ul className="p-2 bg-slate-100">
          <li><a>Submenu 1</a></li>
          <li><a>Submenu 2</a></li>
        </ul>
      </li> */}
          <li><NavLink className='active:font-semibold' to='/services'>Services</NavLink></li>
        </ul>
      </div>
      <div className="navbar-end flex justify-end">
      <button onClick={()=>handleToggleModal(true)} className="btn bg-gradient-to-r from-blue-900 to-sky-600 text-white border-none  w-[120px] sm:w-40">
          Get started
        </button> 
      </div>
     
      {/* </div> */}
    </div><ContactModal open={toggleModal} onClose={()=>handleToggleModal(false)} />
  </>)
}

export default NavBar
