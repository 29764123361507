import About from "./components/About"
import Brands from "./components/Brands"
import CaseStudies from "./components/CaseStudies"
import Show from "./components/Show"
import Feature from "../../components/cards/Feature"
import Carousal from "./components/Carousal"
import { pages } from "../../content"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { contactContext } from "../../context"
function Homepage() {
    const navigate = useNavigate()
    const { setToggle } = useContext(contactContext)
    const handleClick = () => setToggle(true)
    const featureCards = [
        {
            title: "Experience",
            count: '6+ years',
            img: 'experience.png'
        },
        {
            title: "Clients",
            count: '3',
            img: 'clients.png'
        }, {
            title: "Projects",
            count: '10+',
            img: 'projects.png'
        }, {
            title: "In Progress",
            count: '2+',
            img: 'progress.png'
        },
    ]
    const navigateTo = () => navigate('/services')
    return (
        <>
            {/* Header */}
            <div className="bg-gradient-to-r from-slate-900 to-slate-400 overflow-hidden">

                {/* showcase */}
                <Show />
            </div>
            {/* Body */}

            <div className="w-full overflow-hidden z-[5]">
                {/* Brands */}
                <Brands />
                <About />
                {/* Case Studies */}
                <div className="w-[90vw] md:w-[70vw] mx-auto mt-1 sm:-mt-14 md:-mt-7 lg:-mt-4">
                    <h1 className="text-6xl  md:text-8xl font-bold text-white ">
                        Our <span className="text-blue-500">
                            Case Studies</span>
                    </h1>
                </div>
                {
                    pages.CaseStudies.map((item, index) => <CaseStudies service={item} even={index % 2} />)
                }
            </div>
            <div className="overflow-x-hidden">
                <div className=" bg-fixed h-[60vh] w-[140vw] relative bottom-0 bg-slate-900 -left-[20vw] -rotate-3 mt-10" >
                    <div className="flex flex-wrap relative top-32 gap-12 md:gap-20 items-center justify-center rotate-3 my-8 ">
                        {featureCards.map((obj) => (
                            <div className="my-2 z-[5]">
                                <Feature title={obj.title} img={obj.img} count={obj.count} />
                            </div>))}
                    </div>
                </div>
                {/* Expertise */}

                <div className="card shadow-xl mx-auto w-[80vw] lg:w-[60vw] my-5 text-white text-2xl md:text-4xl font-bold p-12 text-center  z-[2] bg-slate-600">
                    <p className="">We Are Increasing Business Success With</p>
                    <p className="text-3xl md:text-5xl my-2">IT Solutions</p>
                    <button onClick={navigateTo} className="p-4 px-8 bg-blue-700 text-white hover:text-blue-700  border-2 hover-bordered border-blue-700 hover:bg-white rounded-2xl mt-5 lg:mt-10 mx-auto text-2xl">Our Services</button>
                </div>
            </div>
            {/* Review Card */}
            {/* <div className="bg-blue-100 min-h-[50vh]">
                <Carousal />
            </div> */}
            <div className="w-[80vw] mx-auto text-white p-2">
                <div className="card shadow-xl p-5 md:p-20 w-full md:w-[50vw] mx-auto text-center bg-blue-600">
                    <h2 className="font-bold text-2xl md:text-4xl mb-6">Let's Work Together!</h2>
                    <p className="text-xl lg:text-2xl">
                        Call us today at +91 88106 67417 to learn more about integrated solutions
                        for IT projects and implementation.
                    </p>

                    <button onClick={handleClick} className="text-xl font-bold bg-slate-200 hover:bg-blue-700 text-blue-700 hover:text-slate-100 w-[50vw] lg:w-[20vw] p-2 py-4 mt-12 mx-auto rounded-xl">
                        Request Demo
                    </button>
                </div>

            </div>
        </>
    )
}

export default Homepage
