import React from 'react'
import { useContext } from 'react'
import { contactContext } from '../../../context'

function Show() {
    const {setToggle} = useContext(contactContext)
    const handleClick = () => setToggle(true)
    return (
        <div className="h-[50vh] w-[90vw] md:w-[80vw] lg:w-[70vw]  mx-auto py-8 px-4 flex items-center justify-between overflow-hidden">
            <img className="w-[100vw] h-[50vh] left-0  absolute bg-cover " src="3409297.jpg" alt="header-cover" />
            <div className="text-4xl z-[10] text-white font-black ">
                <p>Unleash the Potential of  
                Innovative
                 <span className=" text-blue-500">{" "}IT Solutions!</span></p>
                <div onClick={handleClick} className="btn bg-blue-700 my-6 text-white hover:bg-blue-950 border-none"> Let's Get Started </div>
            </div>
            

        </div>
    )
}

export default Show
