import { brands } from "../../../content"

function Brands() {
    return (
        <div className="bg-slate-100 px-10 py-20 z-[10]">
            <div className="lg:max-w-[70vw] flex flex-wrap justify-center mx-auto gap-8">
                {
                    brands.reverse().map(item=><img alt="brands we work(ed) for" className="max-w-[18vw] sm:h-[7vh] filter contrast-50 hover:filter-none chat-image lg:min-w-[10vw]" src={item} />)
                }
            </div>
        </div>
    )
}

export default Brands
