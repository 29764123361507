
function CaseStudies({rounded=true, even=false, service}) {
    const {title, description, mainpara, img} = service
    return (
        <div className={`flex flex-col ${even ? 'lg:flex-row-reverse':'lg:flex-row'} lg:justify-between items-center gap-6 lg:w-[70vw] mx-auto my-12 bg-none`}>
            <figure className="card bg-base-100 shadow-xl ">
                <img className="min-w-[30vw] h-[45vh] rounded-2xl" src={img} alt="Album" />
            </figure>
            <div className="card text-white p-10">
                <h2 className="card-title text-5xl font-bold  my-3 pb-3">{title}</h2>
                <p className="text-xl">{mainpara? description[mainpara]:description[0]}</p>
                <div className="card-actions text-xl font-semibold my-3">
                    <div className="underline underline-offset-4 cursor-pointer">Know More</div>
                </div>
            </div>
        </div>
    )
}

export default CaseStudies
