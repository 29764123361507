import { pages } from "../content"
import CaseStudies from "./index/components/CaseStudies"


function Services() {
  return (
    <div className="w-[90vw] md:w-[80vw] lg:w-[70vw] mx-auto text-white  ">
      <div className="lg:max-w-[50vw] p-10 pl-0 text-2xl my-10">
        <p>
        {pages.servicespage.intro}
      </p>
      </div>
      
      <div className="my-3 lg:-mt-4">
        <h1 className="text-6xl  md:text-8xl font-bold">
          Our <span className="text-blue-500">
            Services</span>
        </h1>
      </div>
      {
        pages.servicespage.services.map((item, index) => <CaseStudies rounded={false} service={item} even={index % 2} />)
      }
    </div>
  )
}

export default Services
