

const homepage = ["Welcome to our startup company! We specialize in automation and full-stack web development.", " Our team of experienced developers is dedicated to creating efficient and effective solutions for your business needs.", " Whether you're looking to automate your workflow or build a new web application, we've got you covered. Contact us today to see how we can help your business grow!"]

const CaseStudies = [{
    title: "UiPath Automation - RPA",
    description: [
        "Tendrils IO is a reputable company that has established partnerships with top brands such as Sofabed and Jennifer to sell their products. As their IT solution provider, we have implemented a cutting-edge UiPath automation solution that has helped to streamline their data processing and management. Our solution automatically populates data into their database and provides a user-friendly dashboard that enables them to make quick and informed decisions.",
        "Our UiPath automation solution has transformed the way Tendrils IO handles their data processing. It has eliminated the need for manual data entry, reduced the risk of errors, and saved valuable time and resources. With our solution in place, Tendrils IO can now focus on their core business functions and leave the data processing to our team of experts.",
        "Our user-friendly dashboard provides Tendrils IO with real-time insights into their data. This enables them to make informed decisions quickly and efficiently. Our team of experienced developers worked closely with Tendrils IO to understand their unique business requirements and design a solution that meets their needs perfectly.",
        "At our company, we are committed to providing our clients with cutting-edge IT solutions that help them to achieve their business goals. Our partnership with Tendrils IO is a testament to our expertise in UiPath automation and our commitment to delivering high-quality solutions that exceed our clients' expectations. Contact us today to learn more about how we can help you streamline your data processing and management using the latest technologies.",
        "Contact us today to learn more about how we can help you streamline your business processes using cutting-edge automation technologies."
    ],
    img: 'RPA.jpg'
}]

const servicespage = {
    intro: "At our company, we offer a wide range of services to meet your needs. Our expertise includes automation, full-stack web development, and everything in between. We work closely with our clients to ensure that we understand their needs and deliver solutions that exceed their expectations. Our team is skilled in a variety of programming languages and technologies, including JavaScript, React, Python, and Node.js. Contact us today to learn more about our services and how we can help your business.",
    services: [
        {
            title: "Automation",
            description: ["We understand the importance of automating your business processes. Automation can save you time, reduce errors, and increase efficiency. Our team of experts can help you automate your workflow using the latest technologies and tools. We'll work with you to identify areas where automation can be implemented and create a customized solution that meets your specific needs. Contact us today to see how we can help you streamline your business processes."],
            img: 'automation.jpg'
        }, {
            title: "MERN Stack Web Development",
            description: ["MERN Stack is a JavaScript-based technology stack that has become increasingly popular in recent years. Comprising of MongoDB, Express.js, ReactJS, and Node.js, it provides a full-stack solution for building powerful, scalable, and responsive web applications.",
                "At our company, we specialize in MERN Stack development and use it to build custom web applications that are tailored to meet the specific needs of our clients. Our portfolio includes a diverse range of projects, from content management systems to e-commerce platforms, all of which have been developed using MERN Stack technology",
                "Our team of experienced developers has a deep understanding of MERN Stack, and we utilize the latest tools and techniques to deliver top-notch web applications that are both efficient and effective. Whether you're looking to create a new web application or improve an existing one, we have the skills and expertise to help you achieve your goals.",
                "Contact us today to learn more about how we can help you create a cutting-edge web application using MERN Stack technology."
            ],
            mainpara: 3,
            img: 'webDevelopment.jpg'
        }
    ]
}

const about = "Our company was founded with the goal of helping businesses automate their processes and build custom web applications. We're a team of experienced developers who are passionate about creating efficient and effective solutions for our clients. Our team is skilled in a variety of programming languages and technologies, and we're dedicated to staying up-to-date with the latest trends and best practices. Contact us today to learn more about our team and how we can help your business."

const contact = "If you're interested in learning more about our services or have any questions, please don't hesitate to contact us. You can reach us by phone, email, or through our online contact form. We look forward to hearing from you and helping your business grow."

export const brands = [
   
]

//export const brands = [
//    "tendrils.webp",
//    "Jennifer.webp",
//   "sofabed.webp"
//]

export const pages = {
    homepage,
    CaseStudies,
    servicespage,
    about,
    contact
}