import { Link } from "react-router-dom"
import { HiOutlineMail, HiPhone } from 'react-icons/hi'

function Footer() {
  return (
    <>
      <div className="mt-3 md:my-6 bg-slate-800 grid grid-flow-row sm:grid-cols-3 sm:flex-row justify-center sm:text-lg font-[450] gap-3 md:gap-8 p-4 md:p-16 text-center">
        {/* {Array(3).fill(<div>
          <h3 className="text-xl md:text-2xl my-2 md:my-4 font-semibold">
            <a href="https://github.com/joshua-murphy">Joshua Murphy</a>
          </h3>
          <div className="text-sm md:text-xl">
            more information Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate, iusto!
          </div>
        </div>).map(item => item)} */}
        <div className="flex flex-col gap-2">
          <img alt="logo" src="mystic-bg.png" className="w-[150px] mx-auto my-3" />
          <div >Mystic Matrix is an IT solution company dedicated to empowering businesses through innovative technology solutions. With a team of highly skilled professionals and a passion for excellence, we deliver comprehensive IT services tailored to meet the specific needs of our clients.</div>
        </div>

        <div className="flex flex-col gap-2 text-start m-auto text-md">
          <Link to='/' className="group flex gap-2 hover:text-blue-800 min-w-[100px] hover:transition hover:translate-x-1 hover:ease-in  hover:duration-300 ">
            {/* <span className="hidden group-hover:block text-blue-800">{"//"}</span> */}
            <span>Home</span></Link>
          <Link to='/services' className="group flex gap-2 hover:text-blue-800 min-w-[100px] hover:transition hover:translate-x-1 hover:ease-in  hover:duration-300 ">
            {/* <span className="hidden group-hover:block text-blue-800">{"//"}</span> */}
            <span>Services</span></Link>
        </div>

        <div className="flex flex-col gap-4 m-auto">
          <div className="flex gap-4">
            <HiPhone size={22} />
            <a className="hover:text-blue-800 min-w-[100px] hover:transition hover:ease-in  hover:duration-300 " href="tel:+918810667417" >+91 88106 67417</a>
          </div>
          <div className="flex gap-4">
            <span><HiOutlineMail size={25} /></span>
            <a className="hover:text-blue-800 min-w-[100px] hover:transition hover:ease-in  hover:duration-300 " href="mailto:info@mysticmatrix.in">info@mysticmatrix.in</a>
          </div>

        </div>
      </div>
      <div className="p-5 pt-0 ">
        <span>&copy; Mystic Matrix Copyright 2023. All rights reserved.  </span>
      </div>
    </>
  )
}

export default Footer
