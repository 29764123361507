import { useState, createContext } from "react";

export const contactContext = createContext()

export function MainContext ({ children }) {
    const [toggleModal, setToggle]= useState(false) 
    return <contactContext.Provider value={{toggleModal, setToggle}}>
        {children}
    </contactContext.Provider>
}

