import React from 'react'
import { pages } from '../../../content'

function About() {
    return ( 
        <div className='text-white md:h-[80vh] w-[100vw] overflow-hidden text-left'>
            <div className='bg-fixed relative bg-gradient-to-r from-slate-900 to-slate-500 h-[100vh] md:h-[60vh] '> </div>
            <div className='flex flex-col md:flex-row justify-center gap-4 sm:gap-6 items-center py-3 relative -top-[90vh] md:-top-[55vh]'>
                <img src='client.jpg' className='w-[40vw]  sm:w-[25vw] rounded-full' alt="experts" />
                <div className='flex flex-col' >
                    {/* <span className=' text-orange-700 font-bold text-lg'>About Us</span> */}
                    <div className='w-[80vw] md:w-[40vw]'>
                        <p className='text-6xl font-extrabold py-3'>What <span className='text-blue-500'>we do</span></p>
                        <p className='text-white font-extralight text-xl'>{pages.about}
                            </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
